// bootstrap
import "@popperjs/core";
import "bootstrap";

import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

// ============= AUTH
import useAuth from "@/use/useAuth.js";
const auth = useAuth();
app.provide("auth", auth);

// ============= AXIOS
import axios from "axios";
// For common config
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
axios.defaults.headers.post["Content-Type"] = "application/json";
const axiosInstance = axios.create({
  timeout: 2000,
  withCredentials: true,
});
app.provide("axiosInstance", axiosInstance);

// ============= TOASTS
import useToast from "@/use/useToast.js";
const toast = useToast();
app.provide("toast", toast);

// ============= GlobalOverlay
import GlobalOverlay from "@/plugins/GlobalOverlay/index";
app.use(GlobalOverlay, {});

// ============= SessionTracker/Timer
import SessionTracker from "@/plugins/SessionTracker/index";
app.use(SessionTracker, {});

// ============= GlobalModal
import GlobalModal from "@/plugins/GlobalModal/index";
app.use(GlobalModal, {});

// ============= ROUTER
import router from "./router";
app.use(router);
// handle redirect if user is not authenticated
router.beforeEach((to, from, next) => {
  //console.log(">>> ROUTER: BeforeEach");
  //console.log(to);

  //const ts = new Date().getTime();

  /*let shouldNotNavigate = false;
  if (app.config.globalProperties.$tracker.value) {
    shouldNotNavigate = true;
  }*/

  if (to.matched.some((record) => record.meta.isRequiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.isSignedIn.value) {
      router.push({
        path: "/",
        query: { redirect: to.fullPath /*, force: true*/ }, // todo: use redirect?
        /*meta: {
          force: true,
        },
        params: {
          force: true,
        },*/
      });
    } else {
      next();

      /*router.go({
        path: to.path,
        //query: { ts: ts },
      });*/
      /*
      if (to.path === "/feed") {
        router.push({
          path: "/feed",
          query: {
            ts: ts,
          },
        });
      } else {
        next();
      }*/
    }
  } else {
    next(); // make sure to always call next()!
  }
});

import { func } from "./func.js";
app.config.globalProperties.$func = func;
app.provide("func", func);

import "video.js/dist/video-js.css";

app.mount("#app");
