//import type { AxiosInstance } from "axios";

// https://dev.to/blindkai/managing-api-layers-in-vue-js-with-typescript-hno
//export type Options = { headers?: Record<string, any>; code?: number };
//export type APIResponse = [null, User, Options?] | [Error, Options?];

// https://www.youtube.com/watch?v=xexLEiwJrnA
export default class ServiceBase {
  constructor() {}

  async handleAPICalls(func) {
    let result = {
      data: undefined,
      isError: false,
      errorMessage: undefined,
      errorCode: undefined,
      errorObject: undefined,
      fieldsErrors: {},
    };

    try {
      const response = await func();

      result.data = response.data;
    } catch (error) {
      //console.log(error);
      //console.log(error.response);

      result.isError = true;
      if (error.response) {
        result.errorCode = error?.response?.status;

        if (error?.response?.data && typeof error?.response?.data === "string") {
          if (error?.response?.statusText) {
            result.errorMessage = error?.response?.statusText + ". " + error?.response?.data;
          } else {
            result.errorMessage = error?.response?.data;
          }
        } else {
          result.errorMessage = error?.response?.statusText;
          const title = error?.response?.data?.title;
          if (title && title != result.errorMessage) {
            // avoid Not Found Not Found message
            result.errorMessage += ". " + title;
          }

          let additionalInformation = [];
          const errors = error?.response?.data?.errors;
          if (errors) {
            for (const key in errors) {
              if (Object.hasOwnProperty.call(errors, key)) {
                const element = errors[key];

                let value = "";
                if (Array.isArray(element) && element.length > 0) {
                  value = element[0];
                } else if (typeof element === "string") {
                  value = element;
                }

                if (value) {
                  let lowerCaseKey = key.toLowerCase();
                  result.fieldsErrors[lowerCaseKey] = value;
                  additionalInformation.push(`${key}: ${value}`);
                }
              }
            }
          }

          if (additionalInformation.length > 0) {
            if (result.errorMessage) {
              result.errorMessage += "\r\n" + additionalInformation.join("\r\n");
            } else {
              result.errorMessage += additionalInformation.join("\r\n");
            }
          }

          result.errorObject = error?.response?.data;
        }

        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);

        if (!result.errorMessage) {
          // p.s. AXIOS produces the message with the same text!
          result.errorMessage = "Request failed with status code " + error.response?.status;
        }
      } else {
        result.errorMessage = error.message;
      }
    }

    return result;
  }
}
