export default function useDate() {
  const getNumberOfSecondsBetweenDates = (d1, d2) => {
    var dif = d1.getTime() - d2.getTime();

    var secondsFromD1toD2 = dif / 1000;
    var secondsBetweenDates = Math.abs(secondsFromD1toD2);

    return Math.trunc(secondsBetweenDates);
  };

  const toLocalISOString = (date) => {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);

    return localISOTime;
  };

  const getReadableDateFromString = (dateStr) => {
    if (!dateStr) {
      return dateStr;
    }

    /*
    // alternative: momentjs
    // https://stackoverflow.com/questions/32540667/moment-js-utc-to-local-time

    // dates magic explained (conversion etc.)
    // https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
    // https://attacomsian.com/blog/javascript-current-timezone
    // https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
    let parsedDate = new Date(dateStr);
    var options = { hour12: false };
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;*/

    return new Date(dateStr).toLocaleString([], {
      hour12: false,
    });
    //return dateStr;
  };

  function substractDaysFromDate(date, count) {
    const zeroHoursDate = new Date(date).setHours(0, 0, 0, 0);
    let newDate = new Date(zeroHoursDate);
    return newDate.setDate(newDate.getDate() - count);
  }

  function getStartOfWeek(date) {
    const currentDayOfWeek = date.getDay();
    let daysToSubstract = currentDayOfWeek - 1;
    if (currentDayOfWeek == 0) {
      // sunday
      daysToSubstract = 6;
    }

    return substractDaysFromDate(new Date(date), daysToSubstract);
  }

  function getStartOfMonth(date) {
    const zeroHoursDate = new Date(date).setHours(0, 0, 0, 0);
    var newDate = new Date(zeroHoursDate);
    newDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), 1);

    return newDate;
  }

  function getStartOfPreviousMonth(date) {
    const zeroHoursDate = new Date(date).setHours(0, 0, 0, 0);
    var newDate = new Date(zeroHoursDate);

    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() - 1);

    return newDate;
  }

  function getStartOfYear(date) {
    const zeroHoursDate = new Date(date).setHours(0, 0, 0, 0);
    var newDate = new Date(zeroHoursDate);
    newDate.setFullYear(newDate.getFullYear(), 0, 1);

    return newDate;
  }

  function getStartOfPreviousYear(date) {
    const zeroHoursDate = new Date(date).setHours(0, 0, 0, 0);
    var newDate = new Date(zeroHoursDate);

    newDate.setDate(1);
    newDate.setMonth(0);
    newDate.setFullYear(newDate.getFullYear() - 1);

    return newDate;
  }

  return {
    getNumberOfSecondsBetweenDates,
    toLocalISOString,
    getReadableDateFromString,
    substractDaysFromDate,
    getStartOfWeek,
    getStartOfMonth,
    getStartOfPreviousMonth,
    getStartOfYear,
    getStartOfPreviousYear,
  };
}
