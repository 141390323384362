<template>
  <transition name="fade">
    <div class="globalOverlay" @click="click" v-show="isShown" :class="{ shown: isShown, clickable: isClickableClosable }"></div>
  </transition>
</template>
<script>
import mitt from "mitt";

import { ref, computed, watchEffect, watch, onMounted, reactive, inject } from "vue";

export default {
  name: "GlobalOverlay",
  setup() {
    const emitter = mitt();

    const isShown = ref(false);
    const isClickableClosable = ref(false);

    const click = () => {
      if (isShown.value && isClickableClosable.value) {
        isShown.value = false;
        emitter.emit("click");
      }
    };

    const show = (isClosable) => {
      isClickableClosable.value = isClosable;
      isShown.value = true;
    };

    const hide = () => {
      isClickableClosable.value = false;
      isShown.value = false;
    };

    return {
      isShown,
      isClickableClosable,
      click,
      show,
      hide,
      emitter,
    };
  },
};
</script>
<style lang="scss">
.globalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--global-overlay-bg-color);
  margin: 0;
  padding: 0;
  /*z-index: var(--z-index-default);
  cursor: pointer;

  // this is to avoid flickering on fade-out effect
  &.shown {
    z-index: var(--z-index-globalOverlay);
  }*/

  z-index: var(--z-index-globalOverlay);

  &.clickable {
    cursor: pointer;
  }
}
</style>
