import ServiceBase from "./ServiceBase";

export default class AuthService extends ServiceBase {
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  async loginWithProvider(code, isGoogleCallback, isFacebookCallback) {
    if (isGoogleCallback) {
      return await this.login(code, "Google");
    } else if (isFacebookCallback) {
      return await this.login(code, "Facebook");
    }
  }

  async login(code, signInType) {
    let request = {
      code: code,
      signInType: signInType,
    };

    const queryFunc = async () => {
      return await this.httpClient.post("Auth/SignIn", request);
    };

    const result = await super.handleAPICalls(queryFunc);
    return result;
  }

  async logout() {
    const queryFunc = async () => {
      return await this.httpClient.get("Auth/SignOut");
    };

    const result = await super.handleAPICalls(queryFunc);
    return result;
  }
}
