<template>
  <div class="card">
    <div class="card-header">404</div>
    <div class="card-body">Page not fond</div>
  </div>
</template>

<script>
import usePageControls from "@/use/usePageControls.js";

import { computed, inject, reactive, ref, watch } from "vue";

export default {
  name: "Page404",
  components: {},
  setup() {
    const pageControlsState = ref(null);
    const pageControls = usePageControls(pageControlsState);
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 2rem 0;
  color: var(--bonus-color);

  .card-header {
    text-align: center;
    font-size: 8rem;
    font-weight: 800;
    line-height: 1;
  }
  .card-body {
    text-align: center;
    color: var(--panel-text-color);
  }
}
</style>
