<template>
  <div id="miniLogo">
    <span class="nameLong reps">Reps</span>
    <span class="nameLong count">Count</span>
    <span class="nameShort reps">R</span>
    <span class="nameShort count">C</span>
  </div>

  <a id="menuBackBtn" class="nav-link" v-if="state.isShowBack" @click="clickBack">
    <span class="nav-link-icon"><i class="fas fa-chevron-left"></i></span>
    <span class="nav-link-text">Back</span>
  </a>

  <a id="menuAddBtn" class="nav-link" v-if="state.isShowAdd" @click="clickAdd">
    <span class="nav-link-icon"><i class="far fa-plus-square"></i></span>
    <span class="nav-link-text">Add</span>
  </a>

  <a id="menuEditBtn" class="nav-link" v-if="state.isShowEdit" @click="clickEdit">
    <span class="nav-link-icon"><i class="fas fa-edit"></i></span>
    <span class="nav-link-text">Edit</span>
  </a>

  <button id="menuCreateBtn" class="btn nav-link" v-if="state.isShowCreate" @click="clickCreate" :disabled="state.isCreateDisabled">
    <span class="nav-link-icon"><i class="far fa-plus-square"></i></span>
    <span class="nav-link-text">Create</span>
  </button>

  <button id="menuSaveBtn" class="btn nav-link" v-if="state.isShowSave" @click="clickSave" :disabled="state.isSaveDisabled">
    <span class="nav-link-icon"><i class="far fa-save"></i></span>
    <span class="nav-link-text">Save</span>
  </button>

  <div class="nav-link-group">
    <a id="menuDeleteBtn" class="nav-link" v-if="state.isShowDelete" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="nav-link-icon"><i class="far fa-trash-alt"></i></span>
      <span class="nav-link-text">Delete</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-end">
      <li class="text-center">
        <span>Are you sure?</span>
      </li>
      <li>
        <a class="dropdown-item" @click="clickDelete">Confirm</a>
      </li>
      <li>
        <a class="dropdown-item">Cancel</a>
      </li>
    </ul>
  </div>

  <a id="menuSearchBtn" class="nav-link" v-if="state.isShowSearch" @click="clickSearch" :class="{ 'router-link-active': state.isSearchOpened }">
    <span class="nav-link-icon"><i class="fas fa-search"></i></span>
    <span class="nav-link-text">Search</span>
  </a>

  <session-tracker v-if="state.isShowTimer" />
  <!-- @click="logout" -->

  <!-- <div class="nav-link-group">
    <a class="nav-link" v-if="state.isShowProfile" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="nav-link-icon dropdown-toggle"><i class="fas fa-user"></i></span>
      <span class="nav-link-text">Profile</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-end">
      <li><a class="dropdown-item" href="#">Action</a></li>
      <li><a class="dropdown-item" href="#">Another action</a></li>
      <li><a class="dropdown-item" href="#">Something else here</a></li>
      <li><hr class="dropdown-divider" /></li>
      <li><a class="dropdown-item" href="#">Separated link</a></li>
    </ul>
  </div> -->

  <div class="nav-link-group" id="profileMenu">
    <a id="menuProfileBtn" class="nav-link" v-if="state.isShowProfile" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="nav-link-icon dropdown-toggle"><i class="fas fa-user"></i></span>
      <span class="nav-link-text">Profile</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-end">
      <li class="usernameInProfileMenu">
        {{ username }}
      </li>
      <li>
        <a class="dropdown-item" @click="viewPublicProfile"><i class="far fa-id-card"></i>Public profile</a>
      </li>
      <li>
        <a class="dropdown-item" @click="editUser"><i class="fas fa-sliders-h"></i>Settings</a>
      </li>
      <!-- <li>
        <a class="dropdown-item">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
          <label class="form-check-label" for="flexSwitchCheckChecked">Dark theme</label>
        </a>
      </li> -->
      <li><hr class="dropdown-divider" /></li>
      <li>
        <a class="dropdown-item" @click="logout"><i class="fas fa-sign-out-alt"></i>Logout</a>
      </li>
    </ul>
  </div>

  <!-- <Test /> -->
</template>

<script>
import mitt from "mitt";

//import Test from "@/components/Test.vue";

import AuthService from "@/services/AuthService.js";

import { ref, reactive, inject } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    //Test,
  },
  setup(props, { emit }) {
    // init
    const emitter = mitt();

    var router = useRouter();

    const toast = inject("toast");
    const globalOverlay = inject("globalOverlay");
    const axiosInstance = inject("axiosInstance");
    const authService = new AuthService(axiosInstance);
    const auth = inject("auth");
    const isSignedIn = auth.isSignedIn;

    const userId = auth.userId.value;

    const copyObj = () => {
      return JSON.parse(JSON.stringify(stateDefaults));
    };

    const stateDefaults = {
      isShowBack: false,

      isShowAdd: false,
      isShowEdit: false,

      isShowCreate: false,
      isCreateDisabled: false,

      isShowSave: false,
      isSaveDisabled: false,

      isShowDelete: false,

      isShowSearch: false,
      isSearchOpened: false,

      isShowTimer: true,

      isShowProfile: true,
    };

    //console.log(stateDefaults);

    const state = ref(copyObj());
    //console.log(state.value);

    const setState = (newState) => {
      //console.log("setState");
      //console.log(newState);
      state.value = newState.value;
    };

    const getDefaultState = () => {
      return copyObj();
    };

    // todo: show warning if something is unsaved
    // todo: detect whether exercise links or group links are edited? (what if accessed directly case?)
    const clickBack = () => {
      router.go(-1);
    };

    const clickAdd = () => {
      emitter.emit("clickAdd");
    };

    const clickCreate = () => {
      emitter.emit("clickCreate");
    };

    const clickEdit = () => {
      emitter.emit("clickEdit");
    };

    const clickSave = () => {
      emitter.emit("clickSave");
    };

    const clickDelete = () => {
      emitter.emit("clickDelete");
    };

    const clickSearch = () => {
      emitter.emit("clickSearch");
      state.value.isSearchoOpened = !state.value.isSearchoOpened;
    };

    const clickTimer = () => {
      // todo: timer bar state bind
    };

    const logout = () => {
      if (!isSignedIn.value) {
        return;
      }

      /*console.log("A0");
      globalOverlay.value.show(false);
      console.log("A00");*/

      authService.logout().then((result) => {
        if (!result.isError) {
          toast.info(`Signed out from the server!`);
        } else {
          toast.warning(
            `Failed to sign out from the server, will proceeed to sign out from the client.
Server error: ${result.errorMessage}`,
            5000
          );
        }

        globalOverlay.value.hide();
        auth.logout();
        router.push("/");
      });
    };

    const viewPublicProfile = () => {
      router.push({
        name: "ViewUser",
        params: { id: userId },
      });
    };

    const editUser = () => {
      router.push({
        name: "EditUser",
        params: { id: userId },
      });
    };

    return {
      username: auth.username,
      emitter,
      state,
      setState,
      getDefaultState,
      clickBack,
      clickAdd,
      clickEdit,
      clickCreate,
      clickSave,
      clickDelete,
      clickSearch,
      clickTimer,
      logout,
      viewPublicProfile,
      editUser,
    };
  },
};
</script>

<style lang="scss" scoped>
#miniLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  padding: 1rem 0;

  //background-color: red;

  .nameShort {
    display: none;
  }

  span.reps {
    color: var(--logo-reps-color);
  }
  span.count {
    color: var(--logo-count-color);
  }

  @include media("<=desktop") {
    .nameLong {
      display: none;
    }

    .nameShort {
      display: unset;
    }
  }

  @include media("<=phone") {
    padding: 0 1rem;
  }
}

.nav-link-group {
  display: flex;

  .nav-link {
    flex-grow: 1;
  }
}

/*
.nav-link-group {
  height: 100%;
  display: flex;

  .nav-link {
    flex-grow: 1;
  }
}*/

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  i {
    height: 100%;
  }
}

#menuCreateBtn {
  color: var(--second-menu-save-color) !important;
}

#menuSaveBtn {
  color: var(--second-menu-save-color) !important;
}

#menuDeleteBtn {
  color: var(--second-menu-delete-color) !important;

  overflow: hidden; // hides P
}

#menuProfileBtn {
  overflow: hidden; // hides P

  .nav-link-icon:after {
    font-size: smaller;
    margin-left: unset;
  }
}

#profileMenu {
  .dropdown-menu {
    .usernameInProfileMenu {
      text-align: center;
      padding: 0 0.3rem 0.5rem 0.3rem;
      font-weight: bold;
      //text-decoration: underline;
    }
  }
}
</style>
