import { ref } from "vue";

const routeCacheDestroyTrigger = ref({
  args: undefined,
});

export default function useCachedState() {
  const notifyCacheDestroyRequired = (value) => {
    routeCacheDestroyTrigger.value = {
      //ts: new Date().getTime(),
      args: value,
    };
  };

  return {
    routeCacheDestroyTrigger,
    notifyCacheDestroyRequired,
  };
}

/*
import { ref } from "vue";

const routeCacheDestroyTrigger = ref(false);
let routeCacheDestroyTriggerArguments = undefined;

export default function useCachedState() {
  const notifyCacheDestroyRequired = (value) => {
    routeCacheDestroyTriggerArguments = value;
    routeCacheDestroyTrigger.value = !routeCacheDestroyTrigger.value;
  };

  const getRouteCacheDestroyTriggerArguments = () => {
    return routeCacheDestroyTriggerArguments;
  };

  return {
    routeCacheDestroyTrigger,
    notifyCacheDestroyRequired,
    getRouteCacheDestroyTriggerArguments,
  };
}
*/
