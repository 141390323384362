<template>
  <Loading :isLoading="state.isLoading" :isError="state.isError" :errorMessage="state.errorMessage" :errorCode="state.errorCode">
    <div class="card" id="siteIntro">
      <div class="card-header">
        <span>Reps</span>
        <span>Count</span>
        <span>.com</span>
      </div>
      <div class="card-body">
        <p>Welcome to the community-driven bank of exercises!</p>
        <ul>
          <li><b>Discover</b> new exercises and exercises's groups</li>
          <li><b>Track</b> workouts</li>
          <li><b>Log</b> sets</li>
          <li class="striked"><b>Analyze</b> performance (TODO)</li>
          <li class="striked"><b>Share</b> your ideas with the community (TODO)</li>
          <li><b>Publish</b> your own content</li>
        </ul>
      </div>
    </div>
    <div class="card" v-if="!isSignedIn" id="signIn">
      <div class="card-header bg-primary">Sign-in</div>
      <div class="card-body">
        <div class="container-fluid">
          <div class="row gy-2">
            <div class="col-xs-12 col-sm-6"><SignInWithFacebook /></div>
            <div class="col-xs-12 col-sm-6"><SignInWithGoogle /></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-header bg-primary">Test</div>
      <div class="card-body">
        <div v-if="isSignedIn">IS SIGNED IN!!!</div>
        <div v-else>not signed in</div>
        <button class="btn btn-primary" @click="loginToggle">login/logout</button>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-primary">Test</div>
      <div class="card-body">
        <button class="btn btn-primary" @click="test1">test1</button>
      </div>
    </div> -->
    <!-- <div class="card" v-else>
      <div class="card-body">
        START dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg
        jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg
        jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg
        jdoklgnjsdfkljg dsgjlkdsf dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdokl gnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdokl gnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl
        kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg jdoklgnjsdfkljg dsgjlkdsf jglkdfj glkdsjfgl kjdsfalkgj dslkfgjlk; dfsajglkdfjsgl kjsdflkg END
      </div>
    </div> -->
  </Loading>
</template>

<script>
import Loading from "@/components/Loading.vue";
import SignInWithFacebook from "@/components/UI/buttons/SignInWithFacebook.vue";
import SignInWithGoogle from "@/components/UI/buttons/SignInWithGoogle.vue";

import AuthService from "@/services/AuthService.js";

import usePageControls from "@/use/usePageControls.js";

import { computed, inject, reactive, watch, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Home",
  components: {
    Loading,
    SignInWithFacebook,
    SignInWithGoogle,
  },
  props: {
    code: String,
    isGoogleCallback: Boolean,
    isFacebookCallback: Boolean,
  },
  setup(props) {
    const pageControlsState = ref(null);
    const pageControls = usePageControls(pageControlsState);

    var router = useRouter();

    const state = reactive({
      isLoading: true,
      isError: false,
      errorMessage: undefined,
      errorCode: undefined,
    });

    const auth = inject("auth");

    const toast = inject("toast");

    const axiosInstance = inject("axiosInstance");
    const authService = new AuthService(axiosInstance);

    const isSignedIn = auth.isSignedIn;

    /*const loginToggle = () => {
      if (isSignedIn.value) {
        // todo: use auth service
        auth.logout();
        router.push("/");
      } else {
        auth.login("alex");
      }
    };*/

    const login = () => {
      //state.isLoading = true;
      state.isError = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      //console.log("1");
      authService.loginWithProvider(props.code, props.isGoogleCallback, props.isFacebookCallback).then((result) => {
        //console.log("2");
        if (!result.isError) {
          const data = result.data;

          auth.login(data.userId, data.username);
          toast.info(`Signed in as ${data.username}!`);
        } else {
          toast.error(`Failed to sign in. Error: ${result.errorMessage}`);
        }

        state.isLoading = false;
        state.isError = result.isError;
        state.errorMessage = result.errorMessage;
        state.errorCode = result.errorCode;
      });
    };

    // run
    //console.log("AUTH: " + props.isGoogleCallback + ", " + props.isFacebookCallback);
    //console.log("code: " + props.code);
    if ((props.isGoogleCallback || props.isFacebookCallback) && props.code) {
      if (isSignedIn.value) {
        toast.warning(`Skipping signing in`);
        state.isLoading = false;
      } else {
        login();
      }
    } else {
      state.isLoading = false;
    }

    //const emitter = mitt();
    //const useCachedStateInstance = useCachedState();
    /*const test1 = () => {
      //useCachedStateInstance.notifyExerciseUpdated();
      //emitter.emit("aaa");

      // Auth/TestCookieSet
      axiosInstance
        .get("Control/GetMaintenanceStatus")
        .then((response) => {
          toast.info(response.data);
        })
        .catch((response) => {
          toast.error(response);
        });
    };*/

    return {
      state,
      isSignedIn,
      //loginToggle,
      //test1,
    };
  },
};

//import mitt from "mitt";
//import useCachedState from "@/use/useCachedState.js";
</script>

<style lang="scss" scoped>
/*@include media-breakpoint-up(sm) {
  body {
    background-color: gray;
  }
}*/

#siteIntro {
  .card-header {
    text-align: center;
    font-size: 4rem;
    font-weight: 800;

    justify-content: center;

    // so the spans of different size would stick to same baseline
    align-items: baseline;

    span:nth-child(1) {
      color: var(--logo-reps-color);

      @include media("<=tablet") {
        font-size: 3rem;
      }

      @include media("<=phone") {
        font-size: 2rem;
      }
    }

    span:nth-child(2) {
      color: var(--logo-count-color);

      @include media("<=tablet") {
        font-size: 3rem;
      }

      @include media("<=phone") {
        font-size: 2rem;
      }
    }

    span:nth-child(3) {
      color: var(--logo-com-color);
      font-size: 2.5rem;

      @include media("<=tablet") {
        font-size: 2rem;
      }

      @include media("<=phone") {
        font-size: 1.5rem;
      }
    }
  }

  .card-body {
    padding: 1rem 2rem;
  }
}

#signIn {
  .card-header {
    padding: 0.5rem 1rem;
  }

  .card-body {
    padding: 1rem 0.5rem;
  }
}

.striked {
  text-decoration: line-through;
}
</style>
