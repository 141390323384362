<template>
  <!-- <VideoJs
    :options="{
      poster: 'https://repscount.dev:5003/media/file/se/1024/64',
    }"
    :src="'https://repscount.dev:5003/media/file/e/1024/63'"
    :contentType="'video/mp4'"
  /> -->

  <div id="sessionTrackerPopupPortal"></div>
  <div id="app-container" class="app-container scrollbarNormal">
    <global-modal />
    <global-overlay />
    <!-- <PageControls /> -->
    <!-- <PageControls ref="pageControlsRef" /> -->

    <div
      class="menu noselect"
      id="mainMenu"
      :class="{
        menuFullWidth: isMainMenuFullWidth,
        menuFullScreen: isMainMenuFullScreen,
      }"
    >
      <div class="menu-container scrollbarCompact">
        <!-- <div class="menu-container-inner">  -->
        <!-- <a class="menuFullWidthBtn menuSpecialBtn" @click="toggleMainMenuFullWidth">
          <span class="nav-link-icon onlyIcon"><i class="fas fa-thumbtack"></i></span>
        </a> -->

        <!-- <a id="mainMenuCloseBtn" @click="mainMenuClose" class="menuSpecialBtn nav-link">
          <span class="nav-link-text">Close</span>
        </a> -->

        <CustomRouterLink name="Home" @click="mainMenuClose" />
        <CustomRouterLink name="Feed" @click="mainMenuClose" />
        <CustomRouterLink name="Exercises" @click="mainMenuClose" />
        <CustomRouterLink name="ExercisesGroups" @click="mainMenuClose" />
        <CustomRouterLink name="SetsGroups" @click="mainMenuClose" />
        <!-- <CustomRouterLink name="Stats" @click="mainMenuClose" /> -->
        <CustomRouterLink name="Feedback" @click="mainMenuClose" />
        <CustomRouterLink name="Privacy" @click="mainMenuClose" />
        <CustomRouterLink name="About" @click="mainMenuClose" />

        <a id="mainMenuOpenBtn" href="#" @click="mainMenuOpen" class="menuSpecialBtn nav-link">
          <span class="nav-link-text">MENU</span>
        </a>

        <div id="copyright">{{ copyright }}</div>
      </div>
    </div>
    <div id="content" class="scrollbarCompact">
      <!--
      <router-view v-slot="{ Component }">
        <keep-alive :include="cachedViews">
          <component :is="Component" :key="$route.fullPath" />
        </keep-alive>
      </router-view>
      -->
      <!-- <div v-show="cachedViews.includes(this.$route.name)">
        <router-view v-slot="{ Component }">
          <keep-alive :include="cachedViews">
            <component :is="Component" :key="$route.fullPath" />
          </keep-alive>
        </router-view>
      </div>
      <div v-if="!cachedViews.includes(this.$route.name)">
        <router-view></router-view>
      </div> -->

      <router-view v-slot="{ Component }">
        <keep-alive :include="cachedViews">
          <component :is="Component" :key="$route.fullPath" />
        </keep-alive>
      </router-view>
    </div>
    <div class="menu noselect" id="secondaryMenu" :class="{ menuFullWidth: isSecondaryMenuFullWidth }" v-if="isSignedIn">
      <div class="menu-container scrollbarCompact">
        <PageControls ref="pageControlsRef" />
      </div>
    </div>
  </div>
</template>
<script>
//import videojs2 from "video.js/dist/video.min.js";
//import videojs2 from "video.js/dist/alt/video.core.novtt.js";

import CustomRouterLink from "@/components/UI/CustomRouterLink.vue";
import PageControls from "@/components/PageControls.vue";

//import VideoJs from "@/components/VideoJs.vue";

//import useLogin from "@/use/useLogin.js";
import useCachedState from "@/use/useCachedState.js";

import { ref, inject, watch, provide, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import useFilePath from "@/use/useFilePath";

export default {
  components: {
    CustomRouterLink,
    PageControls,
    //VideoJs
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    /*let routesToComponentNamesMappings = {};
    const routes = router.getRoutes();
    for (const route of routes) {
      routesToComponentNamesMappings[route.path] = route.components.default().name;
    }*/

    const useFilePathInstance = useFilePath();
    useFilePathInstance.updateViewportWidth();
    provide("useFilePath", useFilePathInstance);

    const useCachedStateInstance = useCachedState();

    const _exercises = "Exercises";
    const _exercisesGroups = "ExercisesGroups";
    const _setsGroups = "SetsGroups";
    const cachedViewsDefault = ["Feed", _exercises, _exercisesGroups, _setsGroups];
    const cachedViews = ref([]);

    //const useLoginInstance = useLogin();
    const auth = inject("auth");

    const isOverlayShown = ref(false);
    const isMainMenuFullScreen = ref(false);
    const isMainMenuFullWidth = ref(false);
    const isSecondaryMenuFullWidth = ref(false);

    // provide PageControls reference to child components
    const pageControlsRef = ref(false);
    provide("pageControls", pageControlsRef);

    const func = inject("func");

    // methods
    const mainMenuOpen = () => {
      isMainMenuFullScreen.value = true;
    };

    const mainMenuClose = () => {
      isMainMenuFullScreen.value = false;
    };

    const toggleMenuWidth = (isMenuFullWidth) => {
      if (isMenuFullWidth.value) {
        isOverlayShown.value = false;
        isMenuFullWidth.value = false;
      } else {
        isOverlayShown.value = true;
        isMenuFullWidth.value = true;
      }
    };

    const toggleMainMenuFullWidth = () => {
      toggleMenuWidth(isMainMenuFullWidth);
      isSecondaryMenuFullWidth.value = false;
    };

    const toggleSecondaryMenuFullWidth = () => {
      toggleMenuWidth(isSecondaryMenuFullWidth);
      isMainMenuFullWidth.value = false;
    };

    const clickOverlay = () => {
      isOverlayShown.value = false;
      isMainMenuFullWidth.value = false;
      isSecondaryMenuFullWidth.value = false;
    };

    // check whether user is logged in
    const isSignedIn = auth.isSignedIn;

    // set the initial cache state
    const setExcludes = (signInValue) => {
      if (!signInValue) {
        cachedViews.value = [];
      } else {
        cachedViews.value = func.convertToObj(cachedViewsDefault);
      }
    };

    setExcludes(isSignedIn.value);

    watch(isSignedIn, (value) => {
      setExcludes(value);
    });

    const disableCache = (name) => {
      if (!name) {
        name = route.name;
      }

      const index = cachedViews.value.indexOf(name);
      if (index > -1) {
        cachedViews.value.splice(index, 1);
        console.log("Cache disabled for: " + name);
      } else {
        console.log("Cache disable skipped for: " + name);
      }
    };

    const enableCache = (name) => {
      if (!isSignedIn.value) {
        return;
      }

      if (cachedViewsDefault.includes(name) && !cachedViews.value.includes(name)) {
        cachedViews.value.push(name);
        console.log("Cache enabled for: " + name);
      } else {
        console.log("Cache enable skipped for: " + name);
      }
    };

    // watch for cache destroy requests
    watch(useCachedStateInstance.routeCacheDestroyTrigger, (value) => {
      if (value.args) {
        var argArray = value.args.split(",");

        for (const variable of argArray) {
          disableCache(variable);
        }
      } else {
        disableCache(value.args);
      }
    });

    // enable caching before navigation
    router.beforeEach((to, from, next) => {
      if (from.name != to.name) {
        enableCache(to.name);
      }

      next();
    });

    const copyright = computed(() => {
      const year = new Date().getFullYear();
      return `© ${year} RepsCount.
All Rights Reserved`;
    });

    return {
      // https://stackoverflow.com/questions/68995879/vue-3-force-unmount-when-deactivated
      cachedViews: computed(() => Array.from(cachedViews.value)),

      pageControlsRef,

      clickOverlay,
      isOverlayShown,

      isMainMenuFullScreen,
      mainMenuOpen,
      mainMenuClose,

      isMainMenuFullWidth,
      toggleMainMenuFullWidth,

      isSecondaryMenuFullWidth,
      toggleSecondaryMenuFullWidth,

      isSignedIn,
      copyright,
    };
  },
};
</script>

<style lang="scss">
@import "@/css/variables";
@import "@/css/bootstrap";
@import "@/css/overrides";
@import "@/css/scrollbars";
//@import "@/css/sanitize"; // replaced by BS
@import "@/css/typography";
//@import "@/css/include-media"; // included globally
//@import "@/css/grid/main.scss"; // replaced by BS

// flex: justify-content - X, align-items - Y, align-flex..

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  overflow: hidden;

  background-color: var(--background-color);
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;

  flex-direction: row;

  .app-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;

    // TBD this is to space stuff evenly!!
    // place-content: space-between; // same as justify content?

    // TDB: this fixes scrollbar when you use margin on input elements
    //box-sizing: border-box;

    overflow: hidden;

    #content {
      flex-grow: 1;

      //display: inline-block;
      display: flex;
      flex-direction: column;

      //padding: 1rem 0;
      //box-sizing: border-box;

      //background-color: red;

      margin-bottom: auto;
      //background-color: black;
    }

    .menu {
      color: var(--panel-text-color);
      background-color: var(--panel-bg-color);

      .menu-container {
        background-color: var(--panel-container-bg-color);
        background-image: var(--panel-background-image);
        display: flex;

        box-shadow: var(--panel-shadow);

        .menuFullWidthBtn {
          display: none;
        }

        .nav-link {
          cursor: pointer;
          background-color: var(--panel-link-bg-color);

          span.nav-link-icon {
            background-color: var(--panel-link-text-bg-color);
          }

          span.nav-link-text {
            background-color: var(--panel-link-icon-bg-color);
          }

          &.router-link-active {
            color: var(--bonus-color);
            background-color: var(--panel-link-hover-bg-color);
            box-shadow: 0 0 15px rgb(0 0 0 / 5%);
          }

          &:hover {
            color: var(--panel-link-hover-color);
            background-color: var(--panel-link-hover-bg-color);
          }
        }

        .menuSpecialBtn {
          color: var(--panel-special-btn-color);
          background-color: var(--panel-special-btn-bg-color);
        }
      }
    }

    #mainMenu {
      #mainMenuCloseBtn {
        display: none;
      }
    }

    #secondaryMenu {
      .nav-link {
        color: var(--second-menu-color);
      }
    }
  }
}

@include media(">phone") {
  body {
    padding: 0.5rem 0;
  }

  #app {
    .app-container {
      flex-direction: row;
      overflow: auto;

      @include media(">phone", "<=desktop") {
        justify-content: space-between;
      }

      #content {
        width: 100%;
        max-width: 600px;

        // for it to scroll together
        min-height: 100%;
        //margin: auto;

        //padding: 0.5rem;
        //box-sizing: border-box;

        //background-color: red;

        margin: 0 0.5rem auto 0.5rem;

        .contentMainColumn {
          //margin: auto;
          //display: table;
          //overflow: hidden;
        }
      }

      .menu {
        z-index: var(--z-index-aboveDefault);
        position: sticky;
        top: 0;

        flex-direction: column;

        width: var(--verticalMenuWidthSm);
        max-width: var(--verticalMenuWidthSm);

        @include media(">desktop") {
          width: var(--verticalMenuWidth);
          max-width: var(--verticalMenuWidth);
        }

        &.menuFullWidth {
          z-index: var(--z-index-overGlobalOverlay);

          .menu-container {
            width: var(--verticalMenuWidth);
          }
        }

        .menu-container {
          height: 100%;
          flex-direction: column;

          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;

          width: var(--verticalMenuWidthSm);

          border-radius: var(--radiusBig);

          transition: width 0.2s ease-in;

          display: flex;
          flex-direction: column;
          justify-self: start;

          @include media(">desktop") {
            width: var(--verticalMenuWidth);
          }

          @include media(">phone", "<=desktop") {
            &:hover {
              width: var(--verticalMenuWidth);
            }
          }

          .menuFullWidthBtn {
            display: block;
          }

          .nav-link {
            border-radius: 4px;
            display: inline-block;

            min-height: var(--menu-item-height);
            height: var(--menu-item-height);

            padding: 0.2rem;
            margin: 0.2rem 0.5rem 0.2rem 0.5rem;
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;

            span.nav-link-icon {
              // to make things centered
              font-size: 1.5em;
              min-width: 40px; // otherwide overflow makes it smaller
              width: 40px;
              justify-content: center;

              //height: 100%; // do not exceed max

              // center icon
              display: flex;
              align-items: center;

              margin-left: 0.6rem;
              // to align icon always in the middle
              //width: 65px;
              //place-content: center;
              //// to avoid icon shrinking
              //flex: none;

              &.onlyIcon {
                margin-left: unset;
                justify-content: center;
              }
            }

            span.nav-link-text {
              //text-transform: uppercase;
              font-size: 1rem;
              font-weight: 600;

              margin-left: 0.6rem;

              // to align text in center
              //flex-grow: 1;
              //text-align: center;

              overflow: hidden;
              text-overflow: ellipsis;

              // to prevent text expanding more than parent
              //height: 100%;
              // AND also to prevent from text not aligning vertically in center
              display: flex;
            }
          }
        }

        @include media(">desktop") {
          .menu-container {
            .menuFullWidthBtn {
              display: none;
            }
          }
        }
      }

      #mainMenu {
        //margin: 0 0.5rem 0 auto;

        @include media(">desktop") {
          //margin: 0 0.5rem 0 0;
        }

        #mainMenuOpenBtn {
          display: none;
        }

        .menu-container a:first-child {
          margin-top: 0;
        }
      }

      #secondaryMenu {
        //margin: 0 auto 0 0.5rem;

        @include media(">desktop") {
          //margin: 0 0 0 0.5rem;
        }

        .menu-container {
          // width right to left
          float: right;
        }
      }
    }
  }
}

@include media("<=phone") {
  #app {
    .app-container {
      flex-direction: column-reverse;

      #content {
        overflow: auto;
        box-sizing: border-box;
        padding: 0.5rem;
      }

      .menu {
        // is needed to be above, so the profile dropdown menu would not go under the public profile header
        z-index: var(--z-index-aboveDefault);
        height: var(--horizontalMenuHeight);
        min-height: var(--horizontalMenuHeight);

        overflow: hidden;

        box-shadow: var(--panel-shadow-phone);

        .menu-container {
          height: 100%;
          width: 100%;

          overflow-y: hidden;
          overflow-x: auto;
          overflow: auto;

          display: inline-flex;
          box-sizing: border-box;

          //padding: 1rem;

          .nav-link {
            display: inline-block;

            min-width: var(--horizontalMenuHeight);

            height: auto;

            padding: 0.2rem;
            margin: 0.2rem;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;

            justify-content: start;

            border-radius: 4px;

            span.nav-link-icon {
              font-size: 16px;
            }

            span.nav-link-text {
              // to always wrap text to newline on space
              //width: min-intrinsic; // old Chrome, Safari
              //width: -webkit-min-content; // less old Chrome, Safari
              //width: -moz-min-content; //current Firefox
              width: min-content; // current Chrome, Safari; not IE or Edge

              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 8px;
              font-weight: 600;
              line-height: 1;
              text-transform: uppercase;

              margin: auto;
            }
          }

          #mainMenuOpenBtn {
            position: sticky;
            right: 0;

            box-shadow: -7px 0px 11px 0px #00000082;
            background-color: var(--panel-container-bg-color);

            // stick to right
            margin-left: auto;
          }
        }

        &.menuFullScreen {
          z-index: var(--z-index-underGlobalOverlay);

          .menu-container {
            position: fixed;
            top: 0;
            height: 100vh;

            overflow-x: hidden;
            overflow-y: auto;

            display: block;

            .nav-link {
              height: var(--horizontalMenuHeight);
              width: 100%;

              span.nav-link-icon {
                font-size: 22px;
              }

              span.nav-link-text {
                width: 100%;

                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px;
                line-height: 1;
                text-transform: none;

                margin: auto;
              }
            }

            #mainMenuOpenBtn {
              display: none;
            }
          }
        }
      }

      #mainMenu.menuFullScreen {
        #mainMenuCloseBtn {
          display: flex;
          position: absolute;
          top: 0;
          right: 0;

          height: var(--horizontalMenuHeight);
          width: var(--horizontalMenuHeight);

          padding: 0.2rem;
          margin: 0.2rem 0 0.2rem 0.2rem;

          color: var(--primary-color);
          border: 1px solid var(--primary-color);
        }
      }
    }
  }
}

// ANIMATE GLOBAL OVERLAY
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#copyright {
  margin-top: auto !important;
  text-align: center;

  text-overflow: ellipsis;
  //overflow: hidden; // comment out in order to ignore overflow of menu container
  white-space: break-spaces;

  margin: 0.2rem 0.5rem 0.2rem 0.5rem;

  @include media("<=desktop") {
    font-size: 0.8rem;
  }

  @include media("<=phone") {
    display: none;
  }
}

/*
#copyrightShort {
  margin-top: auto !important;
  text-align: center;

  display: none;

  @include media("<=desktop") {
    display: unset;
  }
}*/
</style>
