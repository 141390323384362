<template>
  <transition name="bounce">
    <div class="globalModal card" v-if="isShown">
      <div class="card-header standardHeader">
        <span>{{ title }}</span>
      </div>
      <div class="card-body" v-if="body">{{ body }}</div>
      <div class="card-footer">
        <button class="btn btn-danger" v-if="isShowCancel">Cancel</button>
        <button class="btn btn-primary">Continue</button>
      </div>
    </div>
  </transition>
  <global-overlay ref="globalOverlayRef" />
</template>
<script>
import mitt from "mitt";

import { ref, computed, watchEffect, watch, onMounted, reactive, inject, onBeforeUnmount } from "vue";

export default {
  name: "GlobalModal",
  setup() {
    const emitter = mitt();

    const globalOverlayRef = ref(null);

    const isShown = ref(false);
    const title = ref(null);
    const body = ref(null);
    const isShowCancel = ref(false);

    const show = ({ isShowCancelp = true, titlep = "Are you sure?", bodyp = "" }) => {
      globalOverlayRef.value.show(true);
      isShown.value = true;

      isShowCancel.value = isShowCancelp;
      title.value = titlep;
      body.value = bodyp;
    };

    const hide = () => {
      isShown.value = false;
      title.value = null;
      body.value = null;
      isShowCancel.value = false;
    };

    const clickOk = () => {
      globalOverlayRef.value.hide();

      emitter.emit("ok");
      hide();
    };

    const clickCancel = () => {
      globalOverlayRef.value.hide();

      emitter.emit("cancel");
      hide();
    };

    onMounted(() => {
      globalOverlayRef.value.emitter.on("click", clickCancel);
    });

    onBeforeUnmount(() => {
      globalOverlayRef.value.emitter.off("click", clickCancel);
    });

    return {
      globalOverlayRef,
      isShown,
      title,
      body,
      isShowCancel,
      clickOk,
      clickCancel,
      show,
      hide,
      emitter,
    };
  },
};
</script>
<style lang="scss" scoped>
.globalModal {
  position: fixed;
  z-index: var(--z-index-overGlobalOverlay);

  top: 15%;
  left: 5%;
  width: 90%;
  //height: 70%;

  box-shadow: unset;

  @include media(">phone", "<=desktop") {
    top: 20%;
    //height: 60%;
    left: 15%;
    width: 60%;
  }

  @include media(">desktop") {
    left: 30%;
    width: 40%;
  }

  .card-header {
    text-align: center;
  }

  .card-footer {
    padding: 0.5rem 1rem;
    justify-content: space-between;
  }
}
</style>
