<template>
  <a id="menuTimerBtn" class="nav-link" @click.prevent="showPopup" :class="{ 'router-link-active': isSessionInProgress }">
    <span class="nav-link-icon">
      <i class="fas fa-stopwatch-20" v-if="!isSessionInProgress"></i>
      <div id="menuTimerCountdownValue" v-else :class="countdownColor">{{ timerValue }}</div>
    </span>
    <span class="nav-link-text">Timer</span>
  </a>

  <teleport to="#sessionTrackerPopupPortal">
    <transition name="bounce">
      <div id="sessionTrackingPopup" class="card" v-show="isShowPopup">
        <div class="card-header">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <button class="nav-link" href="#" :class="{ active: isShowPopupTimerTab }" @click.prevent="openTabTimer">Timer</button>
            </li>
            <li class="nav-item">
              <button class="nav-link" href="#" :class="{ active: isShowPopupStatsTab }" @click.prevent="openTabStats">Stats</button>
            </li>
            <li class="nav-item">
              <button class="nav-link" href="#" :class="{ active: isShowPopupSettingsTab }" @click.prevent="openTabSettings">Settings</button>
            </li>
            <div class="expander"></div>
            <li class="nav-item closeBtn">
              <button class="nav-link active" href="#" @click.prevent="showSticky"><i class="fas fa-times"></i></button>
            </li>
          </ul>
        </div>
        <div class="card-body scrollbarCompactInverse">
          <!-- ======================= -->
          <!-- timer tab contents -->
          <!-- ======================= -->
          <div class="timerTabContents" v-if="isShowPopupTimerTab">
            <div class="countdownWrapper" @click="startStopTimer">
              <div class="countdownValue" :class="countdownColor">{{ timerValue }}</div>
            </div>
            <div class="">
              <div class="input-group countdownValueAdderWrapper">
                <button class="btn btn-light" @click="timerAdderValueSubstract"><i class="fas fa-minus"></i></button>
                <input type="number" class="form-control bg-dark text-white" v-model="timerValueAdder" />
                <button class="btn btn-light" @click="timerAdderValueAdd"><i class="fas fa-plus"></i></button>
              </div>
              <div class="input-group countdownValueAdderWrapper">
                <span class="input-group-text" id="basic-addon1">Duration</span>
                <input type="number" class="form-control" v-model="timerMaxValue" />
                <select class="form-select" aria-label="Default select example" v-model="timerMaxValue">
                  <option value="10">10s</option>
                  <option value="15">15s</option>
                  <option value="30">30s</option>
                  <option value="45">45s</option>
                  <option value="60">1m</option>
                  <option value="90">1m 30s</option>
                  <option value="120">2m</option>
                  <option value="180">3m</option>
                  <option value="240">4m</option>
                  <option value="300">5m</option>
                </select>
              </div>
            </div>
          </div>

          <!-- ======================= -->
          <!-- stats tab contents -->
          <!-- ======================= -->
          <div class="statsTabContents" v-else-if="isShowPopupStatsTab">
            <!-- <div class="">Session start time: {{ sessionPrettyStartTime }}</div>
            <div class="">Session duration: {{ prettySessionDuration }}</div>
            <div class="">Unique exercises done: {{ countUniqueExercises }}</div>

            <div class="statsRow" v-for="set of sessionSets" :key="set.id">
              <div class="statsRowTitle" v-if="set.isShowHeader">{{ set.header }}</div>
              <div class="statsRowData">
                <i class="fas fa-check"></i>
                <div class="singleStat" v-if="set.reps">Reps: {{ set.reps }}</div>
                <div class="singleStat" v-if="set.weight">Weight: {{ set.weight }}</div>
                <div class="singleStat" v-if="set.distance">Distance: {{ set.distance }}</div>
                <div class="singleStat" v-if="set.time">Time: {{ set.time }}</div>
              </div>
            </div> -->
          </div>

          <!-- ======================= -->
          <!-- settings tab contents -->
          <!-- ======================= -->
          <div class="settingsTabContent" v-else-if="isShowPopupSettingsTab">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isPlaySounds" id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">Play sounds</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isKeepScreenAwake" id="flexCheckDefault0" />
              <label class="form-check-label" for="flexCheckDefault0">Keep screen awake when timer countdown is ON (may affect battery life)</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isContinueTimerNegative" id="flexCheckDefault2" />
              <label class="form-check-label" for="flexCheckDefault2">Continue negative countdown after 0</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isResetTimerWhenStop" id="flexCheckDefault4" />
              <label class="form-check-label" for="flexCheckDefault4">Set timer value to maximum value ({{ timerMaxValue }}) when stopping</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isAutoHideAfterZero" id="flexCheckDefault3" />
              <label class="form-check-label" for="flexCheckDefault3">Auto-hide when 0 countdown is reached</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="isShouldStartTimerOnSetLog" id="flexCheckDefault5" />
              <label class="form-check-label" for="flexCheckDefault5">Auto-start timer after logging a set</label>
            </div>
            <div class="form-check" v-show="isShouldStartTimerOnSetLog">
              <input class="form-check-input" type="checkbox" v-model="isShouldStartTimerOnSetLogEvenWorking" id="flexCheckDefault6" />
              <label class="form-check-label" for="flexCheckDefault6">Option above shall affect working timer</label>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-success" @click="startSession" v-if="!isSessionInProgress">Start session</button>
          <button type="button" class="btn btn-danger" @click="endSession" v-if="isSessionInProgress">End session</button>

          <div class="btn-group" role="group">
            <button type="button" class="btn btn-primary" @click="resetTimer" v-if="!isResetTimerWhenStop">Reset timer</button>
            <button type="button" class="btn btn-warning" @click="stopTimer" v-if="isTimerWorking">Stop timer</button>
            <button type="button" class="btn btn-success" @click="startTimer" v-else>Start timer</button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
<script>
//import plugin from "../../ProgressTracker.js";
//import { events } from "../events.js";

import useSessionTracker from "@/plugins/SessionTracker/use/useSessionTracker.js";
import useCookies from "@/use/useCookies.js";
import useDate from "@/use/useDate.js";
import useArray from "@/use/useArray.js";

import NoSleep from "nosleep.js";

import { useRouter, useRoute } from "vue-router";
import { ref, computed, watchEffect, watch, onMounted, reactive, inject, onUnmounted, onBeforeUnmount } from "vue";

export default {
  name: "SessionTracker",
  setup() {
    // init
    const router = useRouter();
    const route = useRoute();
    const tracker = useSessionTracker();
    const cookies = useCookies();
    const dates = useDate();
    const arrays = useArray();
    var stepSound = new Audio("/sounds/timer/step.mp3");
    stepSound.load();
    var beepSound = new Audio("/sounds/timer/beep.mp3");
    beepSound.load();
    var noSleep = new NoSleep();

    // inject
    const globalOverlay = inject("globalOverlay");

    // state
    const sessionCookieName = "_sc";
    var isSessionInProgress = ref(false);
    const sessionStartDate = ref(undefined);
    let sessionSets = ref([]);
    var sessionDuration = ref(0);
    let sessionDurationTimer = undefined;
    var countUniqueExercises = ref(0);
    var countTotalSets = ref(0);

    var isShowPopup = ref(false); //
    var isShowPopupTimerTab = ref(true);
    var isShowPopupStatsTab = ref(false);
    var isShowPopupSettingsTab = ref(false);

    const timerValueAdder = ref(15);
    const timerMaxValue = ref(60);
    const timerValue = ref(timerMaxValue.value);
    var myTimer = undefined;
    const isTimerWorking = ref(false);

    const isPlaySounds = ref(true);
    const isKeepScreenAwake = ref(true);
    const isContinueTimerNegative = ref(true);
    const isResetTimerWhenStop = ref(true);
    const isAutoHideAfterZero = ref(true);
    const isShouldStartTimerOnSetLog = ref(true);
    const isShouldStartTimerOnSetLogEvenWorking = ref(true);

    const stickyRef = ref(null);
    var isShowSticky = ref(!isShowPopup.value); //
    let maxHeight = 0;
    let offset = 0;

    // methods
    globalOverlay.value.hide();

    const onGlobalOverlayClick = () => {
      console.log("clicked");
      if (!isShowPopup.value) return;

      showSticky();
    };

    globalOverlay.value.emitter.on("click", onGlobalOverlayClick);

    const setSessionDefaults = (shallStart = false) => {
      isSessionInProgress.value = shallStart;
      sessionStartDate.value = new Date();
      sessionSets.value = [];
      /*
      sessionSets.value = [
        {
          id: 1,
          reps: 1,
          exerciseId: 1, // 1
          groupId: 1,
          exerciseName: "ex 1",
          groupName: "gr 1",
        },
        {
          id: 1,
          reps: 1,
          exerciseId: 1,
          exerciseName: "ex 1",
        },
        {
          id: 1,
          reps: 1,
          groupId: 1,
          groupName: "gr 1",
        },
        {
          id: 2,
          reps: 2,
          weight: 10,
          exerciseId: 2, // 2
          exerciseName: "ex 2",
        },
        {
          id: 3,
          reps: 3,
          weight: 15,
          time: 35,
          exerciseId: 3, // 3
          exerciseName: "ex 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 2, // 4
          groupName: "gr 2",
        },
        {
          id: 5,
          reps: 30,
          weight: 15,
          time: 35,
          exerciseId: 2,
          exerciseName: "ex 2",
        },
        {
          id: 1,
          reps: 1,
          groupId: 1,
          groupName: "gr 1",
        },
        {
          id: 4,
          reps: 4,
          groupId: 2,
          groupName: "gr 2",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
        {
          id: 4,
          reps: 4,
          groupId: 3, // 5
          groupName: "gr 3",
        },
      ];*/
      sessionDuration.value = 0;
      //console.log(isSessionInProgress.value);
      //console.log(sessionSets.value);
      //console.log(Object.assign({}, sessionSets.value));
      //console.log(JSON.parse());
    };

    setSessionDefaults();

    const startSession = () => {
      if (isSessionInProgress.value) {
        return;
      }

      setSessionDefaults(true);
      addSetsMetainformation();
      startSessionCounter();
      saveSession();

      console.log(sessionSets.value);
    };

    const startSessionCounter = () => {
      sessionDurationTimer = setInterval(function () {
        sessionDuration.value = sessionDuration.value + 1;
      }, 1000);
    };

    const endSession = () => {
      if (!isSessionInProgress.value) {
        return;
      }

      isSessionInProgress.value = false;
      countUniqueExercises.value = 0;

      setSessionDefaults();

      if (sessionDurationTimer) {
        clearInterval(sessionDurationTimer);
      }

      sessionDurationTimer = undefined;

      stopTimer();
      saveSession();
    };

    const startTimer = () => {
      if (isTimerWorking.value) {
        return;
      }

      startSession();

      isTimerWorking.value = true;

      myTimer = setInterval(function () {
        timerValue.value = timerValue.value - 1;
        if (isPlaySounds.value && timerValue.value > 0 && timerValue.value <= 10) {
          stepSound.play();
        } else if (timerValue.value === 0) {
          // play sound
          if (isPlaySounds.value) {
            beepSound.play();
          }

          // hide after reaching 0
          if (isAutoHideAfterZero.value) {
            showSticky();
          }

          // continue to negative or not
          if (!isContinueTimerNegative.value) {
            stopTimer();
            return;
          }
        }
      }, 1000);
    };

    const stopTimer = () => {
      if (!isTimerWorking.value) {
        if (isResetTimerWhenStop.value) {
          timerValue.value = timerMaxValue.value;
        }

        return;
      }

      if (myTimer) {
        clearInterval(myTimer);
      }

      myTimer = undefined;
      isTimerWorking.value = false;

      if (isResetTimerWhenStop.value) {
        timerValue.value = timerMaxValue.value;
      }
    };

    const startStopTimer = () => {
      if (!isTimerWorking.value) {
        startTimer();
      } else {
        stopTimer();
      }
    };

    const resetTimer = () => {
      stopTimer();
      timerValue.value = timerMaxValue.value;
    };

    const timerAdderValueSubstract = () => {
      const newValue = timerValue.value - timerValueAdder.value;
      if (newValue > 0 && newValue < 10000) {
        timerValue.value = newValue;
      }
    };

    const timerAdderValueAdd = () => {
      timerValue.value = timerValue.value + timerValueAdder.value;
    };

    const openTabTimer = () => {
      isShowPopupTimerTab.value = true;
      isShowPopupStatsTab.value = false;
      isShowPopupSettingsTab.value = false;
    };

    const openTabStats = () => {
      isShowPopupTimerTab.value = false;
      isShowPopupStatsTab.value = true;
      isShowPopupSettingsTab.value = false;
    };

    const openTabSettings = () => {
      isShowPopupTimerTab.value = false;
      isShowPopupStatsTab.value = false;
      isShowPopupSettingsTab.value = true;
    };

    /*
    const startDrag = (event) => {
      //console.log(event);
      //console.log("startDrag");
      //console.log(stickyRef.value);
      //console.log(stickyRef.value.clientHeight);
      maxHeight = window.innerHeight - stickyRef.value.clientHeight;

      const heihgtAtStart = event.screenY;
      const elementPositionAtStart = stickyRef.value.getBoundingClientRect().top;
      offset = elementPositionAtStart - heihgtAtStart;

      //console.log("heihgtAtStart: " + heihgtAtStart);
      //console.log("elementPositionAtStart: " + elementPositionAtStart);
      //console.log("offset: " + offset);

      return false;
    };

    // overDrag is laggy but it works in FF, while drag is not (not showing clientX)
    // todo: try getting rid of lag by setting the RIGHT to 0 on end of drag, while not limiting right during drag itself
    const overDrag = (event) => {
      //console.log("overDrag");
      //console.log(event);
      event.preventDefault();

      dragging(event);
    };

    const dragging = (event) => {
      //console.log("dragging");
      //console.log(event);
      if (event.screenY === 0) return;

      const newPosition = offset + event.screenY;
      if (newPosition > 0 && newPosition < maxHeight) {
        stickyRef.value.style.top = newPosition + "px";
      }
    };*/

    const showSticky = () => {
      isShowSticky.value = true;
      isShowPopup.value = false;
      globalOverlay.value.hide();
    };

    const showPopup = () => {
      globalOverlay.value.show(true);
      isShowSticky.value = false;
      isShowPopup.value = true;
    };

    const saveSession = () => {
      const cookieSaveData = {
        isInProgress: isSessionInProgress.value,
        startDate: sessionStartDate.value.toISOString(),
        //sets: JSON.parse(JSON.stringify(sessionSets.value)), // way to convert nested proxy to object
        settings: {
          isPlaySounds: isPlaySounds.value,
          isKeepScreenAwake: isKeepScreenAwake.value,
          isContinueTimerNegative: isContinueTimerNegative.value,
          isResetTimerWhenStop: isResetTimerWhenStop.value,
          isAutoHideAfterZero: isAutoHideAfterZero.value,
          isShouldStartTimerOnSetLog: isShouldStartTimerOnSetLog.value,
          isShouldStartTimerOnSetLogEvenWorking: isShouldStartTimerOnSetLogEvenWorking.value,
          timerValueAdder: timerValueAdder.value,
          timerMaxValue: timerMaxValue.value,
        },
      };

      const cookieSaveDataString = JSON.stringify(cookieSaveData);

      //console.log("saveSession");
      //console.log(cookieSaveData);
      //console.log(cookieSaveDataString);

      // this duplictes data!
      console.log(cookieSaveData);
      //console.log(cookieSaveDataString);
      cookies.setCookie(sessionCookieName, cookieSaveDataString, 1);
    };

    const loadSession = () => {
      const sessionCookie = cookies.getCookie(sessionCookieName);
      //console.log("loadSession");
      //console.log(sessionCookie);

      if (sessionCookie) {
        //console.log("sessionCookie");
        // invlid JSON shall not crash the app
        try {
          const sessionCookieParsed = JSON.parse(sessionCookie);
          //console.log("sessionCookieParsed");
          //console.log(sessionCookieParsed);
          //console.log(Object.keys(sessionCookieParsed).length);
          // validate is object
          //console.log(Object.keys(sessionCookieParsed).length);
          if (sessionCookieParsed && sessionCookieParsed.constructor === Object && Object.keys(sessionCookieParsed).length === 3) {
            console.log("is object");
            // validate contains keys
            if ("isInProgress" in sessionCookieParsed && "startDate" in sessionCookieParsed && "settings" in sessionCookieParsed && sessionCookieParsed.isInProgress) {
              //console.log("LOADING"); //  "sets" in sessionCookieParsed

              // load data
              isSessionInProgress.value = sessionCookieParsed.isInProgress;
              sessionStartDate.value = new Date(sessionCookieParsed.startDate);
              //sessionSets.value = sessionCookieParsed.sets;

              sessionDuration.value = dates.getNumberOfSecondsBetweenDates(sessionStartDate.value, new Date());

              isPlaySounds.value = sessionCookieParsed.settings.isPlaySounds;
              isKeepScreenAwake.value = sessionCookieParsed.settings.isKeepScreenAwake;
              isContinueTimerNegative.value = sessionCookieParsed.settings.isContinueTimerNegative;
              isResetTimerWhenStop.value = sessionCookieParsed.settings.isResetTimerWhenStop;
              isAutoHideAfterZero.value = sessionCookieParsed.settings.isAutoHideAfterZero;
              isShouldStartTimerOnSetLog.value = sessionCookieParsed.settings.isShouldStartTimerOnSetLog;
              isShouldStartTimerOnSetLogEvenWorking.value = sessionCookieParsed.settings.isShouldStartTimerOnSetLogEvenWorking;

              timerValueAdder.value = sessionCookieParsed.settings.timerValueAdder;
              timerMaxValue.value = sessionCookieParsed.settings.timerMaxValue;

              startSessionCounter();
              //addSetsMetainformation();
            }
          }
        } catch (error) {
          console.error("Failed to parse session data json");
          console.error(error);
        }
      }
    };

    /*watch(sessionSets, () => {
      saveSession();
    });*/

    const createSet = (set) => {
      const idx = arrays.findIndexBy(sessionSets.value, set.id, "id");
      if (idx === -1) {
        //console.log(set);
        sessionSets.value.unshift(set);

        addSetsMetainformation();
        saveSession();
      }

      // start timer when logging new set
      if (isSessionInProgress.value) {
        if (isShouldStartTimerOnSetLog.value) {
          if (!isShouldStartTimerOnSetLogEvenWorking.value && isTimerWorking.value) {
            return;
          }

          resetTimer();
          startTimer();
          showPopup();
        }
      }
    };

    const updateSet = (set) => {
      for (const setToUpdate of sessionSets.value) {
        if (setToUpdate.id === set.id) {
          setToUpdate.reps = set.reps;
          setToUpdate.weight = set.weight;
          setToUpdate.time = set.time;
          setToUpdate.distance = set.distance;
          break;
        }
      }

      addSetsMetainformation();
      saveSession();
    };

    const deleteSet = (set) => {
      arrays.removeFromArrayBy(sessionSets.value, set.id, "id");

      addSetsMetainformation();
      saveSession();
    };

    const addSetsMetainformation = () => {
      let uniqueExercisesCount = 0;
      let uniqueIds = [];
      let uniqueIds2 = [];
      let counter = 0;

      let lastExerciseId = undefined;
      let lastSetsGroupId = undefined;
      for (let setToUpdate of sessionSets.value) {
        //console.log(`========================= ${setToUpdate.exerciseName || setToUpdate.groupName}`);
        //console.log(`lastExerciseId=${lastExerciseId}, lastSetsGroupId=${lastSetsGroupId}`);
        //console.log(`exerciseId=${setToUpdate.exerciseId}, groupId=${setToUpdate.groupId}`);

        /*
        if (lastExerciseId === setToUpdate.exerciseId || lastSetsGroupId === setToUpdate.groupId) {
          console.log(`-`);
          setToUpdate.isShowHeader = false;

          if (lastExerciseId) {
            setToUpdate.exerciseId = lastExerciseId;
          }

          if (lastSetsGroupId) {
            setToUpdate.groupId = lastSetsGroupId;
          }
        } else {
          console.log(`SHOW`);
          setToUpdate.isShowHeader = true;
          setToUpdate.header = setToUpdate.exerciseName || setToUpdate.groupName;
        }
        */
        if ((lastExerciseId !== setToUpdate.exerciseId || lastExerciseId === undefined) && (lastSetsGroupId !== setToUpdate.groupId || lastSetsGroupId === undefined)) {
          //console.log(`SHOW HEADER`);
          setToUpdate.isShowHeader = true;
          setToUpdate.header = setToUpdate.exerciseName || setToUpdate.groupName;

          /*if (lastExerciseId) {
            setToUpdate.exerciseId = lastExerciseId;
          }

          if (lastSetsGroupId) {
            setToUpdate.groupId = lastSetsGroupId;
          }*/
        } else {
          //console.log(`NO HEADER`);
          setToUpdate.isShowHeader = false;

          if (lastExerciseId) {
            setToUpdate.exerciseId = lastExerciseId;
          }

          if (lastSetsGroupId) {
            setToUpdate.groupId = lastSetsGroupId;
          }
        }

        lastExerciseId = setToUpdate.exerciseId;
        lastSetsGroupId = setToUpdate.groupId;

        if (lastExerciseId && uniqueIds.includes(lastExerciseId)) {
          // set belongs to an existing exercise
        } else if (lastSetsGroupId && uniqueIds2.includes(lastSetsGroupId)) {
          // set belongs to an existing group
        } else {
          if (lastExerciseId) {
            uniqueIds.push(lastExerciseId);
          }

          if (lastSetsGroupId) {
            uniqueIds2.push(lastSetsGroupId);
          }

          uniqueExercisesCount++;
        }
      }

      countUniqueExercises.value = uniqueExercisesCount;
      countTotalSets.value = counter;
    };

    const countdownColor = computed(() => {
      if (!isTimerWorking.value) {
        return "neutralCountdown";
      } else {
        if (timerValue.value >= 0) {
          return "positiveCountdown";
        } else {
          return "negativeCountdown";
        }
      }
    });

    watch(timerMaxValue, () => {
      if (!isTimerWorking.value) {
        timerValue.value = timerMaxValue.value;
      }
    });

    watchEffect(() => {
      if (isShowPopup.value && isTimerWorking.value && isKeepScreenAwake.value) {
        noSleep.enable();
      } else {
        noSleep.disable();
      }
    });

    var prettySessionDuration = computed(() => {
      // https://stackoverflow.com/questions/1322732/convert-seconds-to-hh-mm-ss-with-javascript
      let totalSeconds = sessionDuration.value;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
    });

    const sessionPrettyStartTime = computed(() => {
      if (isSessionInProgress.value) {
        return dates.getReadableDateFromString(sessionStartDate.value);
      } else {
        return "-";
      }
    });

    let routerBeforeEach = router.beforeEach((to, from, next) => {
      /*console.log(">>> FROM");
      console.log(from);
      console.log(from.query);
      console.log(from.params);
      console.log(from.meta);

      console.log(">>> TO");
      console.log(to);
      console.log(to.query);
      console.log(to.params);
      console.log(to.meta);*/

      if (isShowPopup.value) {
        showSticky();
        next(false);
      } else {
        next();
      }
    });

    onBeforeUnmount(() => {
      // cleanup stuff

      // timer is still working in background after logout
      if (myTimer) {
        clearInterval(myTimer);
      }

      if (globalOverlay.value && globalOverlay.value.emitter) {
        globalOverlay.value.emitter.off("click", onGlobalOverlayClick);
      }

      routerBeforeEach();
    });

    onUnmounted(() => {
      console.log("UNMOUNTED");
      //routerBeforeEach();
    });

    loadSession();
    addSetsMetainformation();

    return {
      isSessionInProgress,
      sessionSets,
      startSession,
      endSession,
      prettySessionDuration,
      countUniqueExercises,
      sessionPrettyStartTime,

      isShowPopup,
      isShowPopupTimerTab,
      isShowPopupStatsTab,
      isShowPopupSettingsTab,
      openTabTimer,
      openTabStats,
      openTabSettings,

      stickyRef,
      isShowSticky,
      //startDrag,
      //dragging,
      //overDrag,

      isTimerWorking,
      timerValue,
      timerMaxValue,
      startTimer,
      stopTimer,
      startStopTimer,
      resetTimer,
      countdownColor,

      timerValueAdder,
      timerAdderValueSubstract,
      timerAdderValueAdd,

      isPlaySounds,
      isKeepScreenAwake,
      isContinueTimerNegative,
      isResetTimerWhenStop,
      isAutoHideAfterZero,
      isShouldStartTimerOnSetLog,
      isShouldStartTimerOnSetLogEvenWorking,

      showSticky,
      showPopup,

      createSet,
      updateSet,
      deleteSet,
    };
  },
};
</script>
<style lang="scss" scoped>
#sessionTrackingPopup {
  position: fixed;
  z-index: var(--z-index-overGlobalOverlay);

  top: 15%;
  left: 5%;
  width: 90%;
  height: 70%;

  background-color: transparent;
  box-shadow: unset;

  @include media(">phone", "<=desktop") {
    top: 20%;
    height: 60%;
    left: 15%;
    width: 60%;
  }

  @include media(">desktop") {
    left: 30%;
    width: 40%;
  }

  .card-header {
    padding: 0;
    border: 0;

    background-color: unset;

    .nav {
      width: 100%;

      .nav-link {
        color: var(--secondary-text-color);
        background-color: var(--secondary-color);

        &.active {
          color: var(--primary-color);
          background-color: var(--primary-text-color);
        }
      }

      .expander {
        display: inline-block;
        flex: 1;
      }

      .closeBtn {
        //margin-left: auto;
      }
    }
  }

  .card-body {
    overflow: auto;
    background-color: var(--secondary-color);

    padding: 0.5rem;

    .timerTabContents {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
    }

    .countdownWrapper {
      padding-bottom: 1rem;
      cursor: pointer;

      text-align: center;

      font-family: fantasy;
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;

      .neutralCountdown {
        color: var(--timer-neutral-countdown);
      }

      .positiveCountdown {
        color: var(--timer-positive-countdown);
      }

      .negativeCountdown {
        color: var(--timer-negative-countdown);
      }

      .countdownValue {
        font-size: 10rem;
        line-height: 10rem;

        @include media(">tablet") {
          font-size: 15rem;
          line-height: 15rem;
        }

        @include media(">desktop") {
          font-size: 20rem;
          line-height: 20rem;
        }
      }
    }

    .countdownValueAdderWrapper:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  .card-footer {
    padding: 0;
    border: 0;

    justify-content: space-between;

    background-color: transparent;

    .btn-group,
    .btn {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

/* ANIMATIONS */
/* popup bouce */
.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: unset;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.7);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

/* ANIMATIONS */
/* side slide */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transform: unset;
}

.slide-fade-enter-from {
  transform: translateX(300px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: unset;
}

@include media("<=phone") {
  #menuTimerBtn {
    margin-left: auto !important;
  }
}

#menuTimerCountdownValue {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 600;
}
</style>
