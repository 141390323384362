export default function useViewportSize() {
  const getViewportSize = () => {
    //return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    //return ((document.compatMode === "CSS1Compat") ? document.documentElement : document.body).clientWidth;

    let w = window;

    // This works for all browsers except IE8 and before
    if (w.innerWidth != null) {
      return { w: w.innerWidth, h: w.innerHeight };
    }

    // For IE (or any browser) in Standards mode
    var d = w.document;
    if (document.compatMode == "CSS1Compat")
      return {
        w: d.documentElement.clientWidth,
        h: d.documentElement.clientHeight,
      };

    // For browsers in Quirks mode
    return {
      w: d.body.clientWidth,
      h: d.body.clientHeight,
    };
  };

  return {
    getViewportSize,
  };
}
