export default function useArray() {
  const findIndexBy = (arrayRef, fieldValue, fieldName) => {
    let index = -1;
    for (var i = 0; i < arrayRef.length; i++) {
      if (arrayRef[i][fieldName] === fieldValue) {
        return i;
      }
    }

    return index;
  };

  const removeFromArrayBy = (arrayRef, fieldValue, fieldName) => {
    const indexOf = findIndexBy(arrayRef, fieldValue, fieldName);
    if (indexOf !== -1) {
      arrayRef.splice(indexOf, 1);
    }
  };

  return {
    findIndexBy,
    removeFromArrayBy,
  };
}
