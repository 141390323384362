import { computed } from "vue";

export const func = {
  isDefined: (data) => {
    /*
    https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
    will evaluate to true if value is not:
        null
        undefined
        NaN
        empty string ("")
        0
        false
    */

    if (data) {
      return true;
    }

    return false;
    //return typeof data != "undefined";
  },
  toObj: (data) => {
    //const res = JSON.stringify(data.value);
    //return JSON.parse(res);
    return { ...data };
  },
  isValid: (error) => {
    console.log(error);
    const isValid = error ? "is-invalid" : "is-valid";
    return isValid;
  },
  getHttpError: (response, fieldName) => {
    /*if (response.errorCode === 400) {
      const errors = response.errorObject?.errors;
      if (errors) {
        if (fieldName in errors) {
          const arrayVal = errors[fieldName];
          if (Array.isArray(arrayVal) && arrayVal.length > 0) {
            return arrayVal[0];
          } else if (typeof arrayVal === "string") {
            return arrayVal;
          }
        }
      }
    }*/

    fieldName = fieldName.toLowerCase();

    if (fieldName in response.fieldsErrors) {
      return response.fieldsErrors[fieldName];
    }

    return undefined;
  },
  convertToObj: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  debounce: (func, duration, funcBefore) => {
    let timeout;
    return function (...args) {
      if (funcBefore) {
        funcBefore();
      }
      const effect = () => {
        timeout = null;
        return func.apply(this, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(effect, duration);
    };
  },
  isSlotPresent: (slots) => {
    if (slots.default) {
      const slotElements = slots.default();

      for (const element of slotElements) {
        const elType = element.type;

        if (elType === "Symbol(Text)") {
          // empty lines are of type Symbol(Text)

          // children == input text
          const isValid = slotElements[0].children && slotElements[0].children.length > 0;

          if (isValid) {
            return true;
          }
        } else {
          // other types of lines that are components have component name in it
          return true;
        }
      }
    }

    return false;
  },
};
