// imports
import { ref, computed } from "vue";

import useCookies from "@/use/useCookies.js";
var useCookiesInstance = useCookies();

// global state
var _isSignedIn = ref(false);
var _userId = ref(null);
var _username = ref(null);

// init
var authCookie = useCookiesInstance.getCookie("_tp");
var userid = localStorage.getItem("userid");
var username = localStorage.getItem("username");

if (authCookie && userid && username) {
  _isSignedIn.value = true;
  _userId.value = Number(userid);
  _username.value = username;
}

// methods
export default function useAuth() {
  const login = (userid, username) => {
    _userId.value = Number(userid);
    _username.value = username;

    _isSignedIn.value = true;

    localStorage.setItem("userid", userid);
    localStorage.setItem("username", username);
  };

  const logout = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    _userId.value = undefined;
    _username.value = undefined;
    _isSignedIn.value = false;
  };

  const isSignedIn = computed(() => {
    return _isSignedIn.value;
  });

  const userId = computed(() => {
    return _userId.value;
  });

  const username = computed(() => {
    return _username.value;
  });

  const update = (username) => {
    _username.value = username;
    localStorage.setItem("username", username);
  };

  return {
    isSignedIn,
    login,
    logout,
    userId,
    username,
    update,
  };
}
