/*export default {
  foo: function () {
    alert("foo!");
  },
};


export default () => {
    // data
  const loading = ref(false);
  const payload = ref(null);

    // methods
  const makeRequest = async (url) => {
    loading.value = true;
    const res = await fetch(url);
    payload.value = await res.json();
  };

    // exposed
  return {
    payload: readonly(payload), //notice the readonly here
    loading: readonly(loading), // and here
    makeRequest
  };
};*/

import useViewportSize from "./useViewportSize";

export default function useFilePath() {
  const apiBaseUrl = process.env.VUE_APP_API_BASE;
  let viewportWidth = 0;

  const updateViewportWidth = () => {
    const { getViewportSize } = useViewportSize();
    var viewportSize = getViewportSize();
    viewportWidth = viewportSize.w;
  };

  const getPath = (id, size) => {
    return `${apiBaseUrl}media/file/${size}/${viewportWidth}/${id}`;
  };

  const getFilePath = (file) => {
    return getPath(file.id, "e");
  };

  const getSmallFilePath = (file) => {
    return getPath(file.id, "se");
  };

  const getThumbnaiilPath = (file) => {
    return getPath(file.thumbnailId, "e");
  };

  const getSmallThumbnailPath = (file) => {
    return getPath(file.thumbnailId, "se");
  };

  return {
    updateViewportWidth,
    getFilePath,
    getSmallFilePath,
    getThumbnaiilPath,
    getSmallThumbnailPath,
  };
}
