import SessionTracker from "@/plugins/SessionTracker/components/SessionTracker.vue";

import { ref } from "vue";

export default {
  install(app, params = {}) {
    const instanceRef = ref(null);

    app.mixin({
      created() {
        if (this.$options.name === "SessionTracker") {
          console.log("Registering SessionTracker");
          instanceRef.value = this;
        }
      },
      beforeUnmount() {
        if (this.$options.name === "SessionTracker") {
          console.log("Unregistering SessionTracker");
          instanceRef.value = null;
        }
      },
    });

    app.component("session-tracker", SessionTracker);

    app.config.globalProperties.$tracker = instanceRef;
    app.provide("tracker", instanceRef);
  },
};
