import { ref, computed, getCurrentInstance } from "vue";

export default function useSessionTracker() {
  const internalInstance = getCurrentInstance();
  const tracker = internalInstance.appContext.config.globalProperties.$tracker;

  const createSet = (set) => {
    tracker.createSet(set);
  };

  const updateSet = (set) => {
    tracker.updateSet(set);
  };

  const deleteSet = (set) => {
    tracker.deleteSet(set);
  };

  return {
    createSet,
    updateSet,
    deleteSet,
  };
}
