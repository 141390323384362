import { computed, ref, reactive, watch, inject, onBeforeUnmount } from "vue";
import { onActivated, onDeactivated } from "vue";
import { useRouter } from "vue-router";

// methods
export default function usePageControls(pageControlsState, clickAddRouterParameter, clickCreateFunction, clickSaveFunction, clickDeleteFunction) {
  const router = useRouter();

  const pageControls = inject("pageControls");
  //const modal = inject("globalModal");

  const clickAdd = () => {
    //console.log("clickAdd");
    if (isDeactivated) return;

    router.push(clickAddRouterParameter); // { name: "Feed", params: { id: props.id } }
  };

  const clickEdit = () => {
    if (isDeactivated) return;

    router.push(clickAddRouterParameter);
  };

  const clickCreate = () => {
    if (isDeactivated) return;

    clickCreateFunction();
  };

  const clickSave = () => {
    if (isDeactivated) return;

    clickSaveFunction();
  };

  const clickDelete = () => {
    if (isDeactivated) return;

    //modal.value.show({});
    clickDeleteFunction();
  };

  const clickSearch = () => {
    //console.log("clickSearch");
    if (isDeactivated) return;

    pageControlsState.value.isSearchOpened = !pageControlsState.value.isSearchOpened;
  };

  const setPageControlsDefaults = () => {
    if (pageControls.value) {
      if (!pageControlsState.value) {
        pageControlsState.value = pageControls.value.getDefaultState();
      }

      pageControls.value.setState(pageControlsState);

      if (/*pageControlsState.value.isShowAdd && */ clickAddRouterParameter) {
        pageControls.value.emitter.on("clickAdd", clickAdd);
      }

      if (/*pageControlsState.value.isShowEdit && */ clickAddRouterParameter) {
        pageControls.value.emitter.on("clickEdit", clickEdit);
      }

      if (/*pageControlsState.value.isShowCreate && */ clickCreateFunction) {
        pageControls.value.emitter.on("clickCreate", clickCreate);
      }

      if (/*pageControlsState.value.isShowSave && */ clickSaveFunction) {
        pageControls.value.emitter.on("clickSave", clickSave);
      }

      if (/*pageControlsState.value.isShowDelete && */ clickDeleteFunction) {
        pageControls.value.emitter.on("clickDelete", clickDelete);
      }

      //if (pageControlsState.value.isShowSearch ) {
      pageControls.value.emitter.on("clickSearch", clickSearch);
      //}
    }
  };

  watch(pageControlsState, () => {
    // human error proof
    if (pageControlsState.value.isShowAdd && !clickAddRouterParameter) {
      pageControlsState.value.isShowAdd = false;
    }

    if (pageControlsState.value.isShowEdit && !clickAddRouterParameter) {
      pageControlsState.value.isShowEdit = false;
    }

    if (pageControlsState.value.isShowCreate && !clickCreateFunction) {
      pageControlsState.value.isShowCreate = false;
    }

    if (pageControlsState.value.isShowSave && !clickSaveFunction) {
      pageControlsState.value.isShowSave = false;
    }

    if (pageControlsState.value.isShowDelete && !clickDeleteFunction) {
      pageControlsState.value.isShowDelete = false;
    }
  });

  setPageControlsDefaults();

  /*watch(pageControls, () => {
    setPageControlsDefaults();
  });
*/

  var isShowingCache = false;
  var isDeactivated = false;

  onActivated(() => {
    if (isShowingCache) {
      if (pageControls.value) {
        // load previous state
        pageControls.value.setState(pageControlsState);
      }
    }

    isDeactivated = false;
  });

  onDeactivated(() => {
    isShowingCache = true;
    isDeactivated = true;
  });

  onBeforeUnmount(() => {
    if (!pageControls.value) {
      return;
    }

    pageControls.value.emitter.off("clickAdd", clickAdd);
    pageControls.value.emitter.off("clickEdit", clickEdit);
    pageControls.value.emitter.off("clickCreate", clickCreate);
    pageControls.value.emitter.off("clickSave", clickSave);
    pageControls.value.emitter.off("clickDelete", clickDelete);
    pageControls.value.emitter.off("clickSearch", clickSearch);
  });

  return {};
}
