// imports
import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";

// methods
export default function useToast() {
  const show = (message, background, color, durationOverride) => {
    let duration = 3000;
    if (durationOverride) {
      duration = durationOverride;
    }

    var toast = Toastify({
      text: message,
      duration: duration,
      close: false,
      //class: "toast-info",
      style: {
        background: background,
        color: color,
      },
      stopOnFocus: true,
      onClick: function () {
        toast.hideToast();
      },
    });

    toast.showToast();
  };

  const success = (message, duration) => {
    info(message, duration);
  };

  const info = (message, duration) => {
    show(message, "linear-gradient(to right, #001eb0, #3da9c9)", "white", duration);
  };

  const warning = (message, duration) => {
    show(message, "linear-gradient(to right, #b0a200, #c9c53d)", "white", duration);
  };

  const error = (message, duration) => {
    show(message, "linear-gradient(to right, #b00039, #c93d4a)", "white", duration);
  };

  return {
    success,
    info,
    warning,
    error,
  };
}

/*import "toastify-js/src/toastify.css";
import Toastify from "toastify-js";

class Toast {
  constructor() {
    //this.name = name;
  }

  info(message) {
    this.show(message, "linear-gradient(to right, #001eb0, #3da9c9)", "white");
  }
  warning(message) {
    this.show(message, "linear-gradient(to right, #b0a200, #c9c53d)", "white");
  }
  error(message) {
    this.show(message, "linear-gradient(to right, #b00039, #c93d4a)", "white");
  }

  show(message, background, color) {
    var toast = Toastify({
      text: message,
      duration: 2000,
      close: false,
      //class: "toast-info",
      style: {
        background: background,
        color: color,
      },
      stopOnFocus: true,
      onClick: function () {
        toast.hideToast();
      },
    });

    toast.showToast();
  }
}

app.provide("toast", new Toast());*/
