<template>
  <div class="card loadingCard" v-if="!isShowContent" :class="customClass">
    <div class="card-body" v-if="isShowLoading">
      <div class="spinner-border text-primary"></div>
      <div class="loadingtext">Loading...</div>
    </div>
    <div class="card-body" v-else-if="isError">
      <div v-if="errorCode === 401">Not authorized to view the contents</div>
      <div v-else-if="errorCode === 404">The content you are looking for not found</div>
      <div v-else-if="errorCode === 500">Iternal server error. Please contact the administrator and provide some steps on how to reproduce the problem.</div>
      <div v-else>
        An error has occured.. Error code: {{ errorCode }} Message:
        {{ errorMessage }}
      </div>
    </div>
  </div>
  <slot v-else />
</template>

<script>
import { ref, computed, watch, watchEffect } from "vue";

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isError: Boolean,
    errorMessage: String,
    errorCode: Number,
    customClass: Object,
  },
  setup(props) {
    const isShowContent = computed(() => {
      return !props.isLoading && !props.isError;
    });

    const isShowLoadingAfterTimeout = ref(false);

    // do not flash the loading screen in front of the eyes..
    // show it only if it takes longer than X time to load the contents
    setTimeout(() => {
      isShowLoadingAfterTimeout.value = true;
    }, 0);

    const isShowLoading = computed(() => {
      return props.isLoading && isShowLoadingAfterTimeout.value;
    });

    /*watch(
      () => props.isLoading,
      () => {
        console.log(`isLoading: ${props.isLoading}`);
        if (!props.isLoading) {
          isShowLoadingAfterTimeout.value = false;
        }
      }
    );*/

    // reset to defaults
    watchEffect(() => {
      if (!props.isLoading && isShowLoadingAfterTimeout.value && !props.isError) {
        isShowLoadingAfterTimeout.value = false;
      }
    });

    return {
      isShowContent,
      isShowLoading: isShowLoading,
    };
  },
};
</script>

<style lang="scss">
.card.loadingCard {
  .card-body {
    padding: 1rem;
    text-align: center;

    .loadingtext {
      margin: auto;
    }
  }
}
</style>
