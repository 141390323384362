<template>
  <router-link :to="path" v-if="isShouldShow" @click="this.$emit('click')" :class="{ 'nav-link': true, 'router-link-active': isShouldBeActive }">
    <span class="nav-link-icon" v-if="icon"><i :class="icon"></i></span>
    <span class="nav-link-text">{{ displayName }}</span>
  </router-link>
</template>

<script>
import { computed, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
  setup(props) {
    const auth = inject("auth");
    const router = useRouter();
    const route = useRoute();

    const allRoutes = router.getRoutes();
    const routesWithSameName = allRoutes.filter(function (element) {
      return element.name === props.name;
    });
    //people.map(function(x) { return (x.gender == 0? 'Mrs.' : 'Mr.') + x.name; });

    //console.log(props.name);
    //console.log(routesWithSameName);

    const path = routesWithSameName[0].path;
    const isRequiresAuth = routesWithSameName[0].meta?.isRequiresAuth;
    const displayName = routesWithSameName[0].meta?.displayName;
    const icon = routesWithSameName[0].meta?.icon;

    let alternativeRoutesNames = null;
    if (routesWithSameName) {
      alternativeRoutesNames = [];

      for (const route of routesWithSameName) {
        if (route.meta && route.meta.alternativeRoutesNames) {
          alternativeRoutesNames.push(...route.meta.alternativeRoutesNames);
        }
      }
    }

    //console.log(alternativeRoutesNames);

    const isShouldShow = computed(() => {
      //console.log("isShouldShow");
      //console.log(route.meta);
      //console.log(route.name);
      //console.log(props.route.name);

      if (!isRequiresAuth || (isRequiresAuth && auth.isSignedIn.value)) {
        return true;
      } else {
        return false;
      }
    });

    const isShouldBeActive = computed(() => {
      //console.log("isShouldBeActive");

      if (alternativeRoutesNames) {
        // triggers call each time route changes
        return alternativeRoutesNames.includes(route.name);
      } else {
        return false;
      }
    });

    return {
      displayName,
      icon,
      path,
      isShouldShow,
      isShouldBeActive,
    };
  },
};
</script>

<style></style>
