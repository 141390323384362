import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Page404 from "@/views/Page404.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    alias: ["/gCallback", "/fCallback"],
    meta: {
      displayName: "Home",
      icon: "fas fa-home",
    },
    props: (route) => {
      const code = route.query.code;

      let isGoogleCallback = false;
      let isFacebookCallback = false;
      if (code) {
        if (route.path === "/gCallback") {
          isGoogleCallback = true;
        } else if (route.path === "/fCallback") {
          isFacebookCallback = true;
        }
      }

      return { code, isGoogleCallback, isFacebookCallback };
    },
  },
  {
    path: "/feed",
    name: "Feed",
    component: () => import(/* webpackChunkName: "feed" */ "@/views/Feed.vue"),
    meta: {
      isRequiresAuth: true,
      displayName: "Feed",
      icon: "far fa-newspaper",
    },
  },
  {
    path: "/users/view/:id",
    name: "ViewUser",
    component: () => import(/* webpackChunkName: "viewUser" */ "@/views/single/view/ViewUser.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/users/edit/:id?",
    name: "EditUser",
    component: () => import(/* webpackChunkName: "editUser" */ "@/views/single/edit/EditUser.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10) || 0;

      return { id };
    },
  },
  {
    path: "/exercises",
    name: "Exercises",
    component: () => import(/* webpackChunkName: "exercises" */ "@/views/Exercises.vue"),
    meta: {
      isRequiresAuth: true,
      alternativeRoutesNames: ["EditExercise", "ViewExercise", "EditExerciseLinkedExercises"],
      displayName: "Exercises",
      icon: "fas fa-dumbbell",
    },
  },
  {
    path: "/exercises/view/:id",
    name: "ViewExercise",
    component: () => import(/* webpackChunkName: "viewExercise" */ "@/views/single/view/ViewExercise.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/exercises/edit/:id?",
    name: "EditExercise",
    component: () => import(/* webpackChunkName: "editExercise" */ "@/views/single/edit/EditExercise.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10) || 0;
      const isNew = id === 0;

      return { id, isNew };
    },
  },
  {
    path: "/exercises/edit/:id/linkedExercises",
    name: "EditExerciseLinkedExercises",
    component: () => import(/* webpackChunkName: "editExerciseLinkedExercises" */ "@/views/single/edit/EditExerciseLinkedExercises.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/exercisesGroups",
    name: "ExercisesGroups",
    component: () => import(/* webpackChunkName: "exercisesGroups" */ "@/views/ExercisesGroups.vue"),
    meta: {
      isRequiresAuth: true,
      alternativeRoutesNames: ["ViewExercisesGroup", "EditExercisesGroup", "EditExercisesGroupLinkedExercises"],
      displayName: "Exercsies's Groups",
      icon: "fas fa-list-alt",
    },
  },
  {
    path: "/exercisesGroups/view/:id",
    name: "ViewExercisesGroup",
    component: () => import(/* webpackChunkName: "viewExercisesGroup" */ "@/views/single/view/ViewExercisesGroup.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/exercisesGroups/edit/:id?",
    name: "EditExercisesGroup",
    component: () => import(/* webpackChunkName: "editExercisesGroup" */ "@/views/single/edit/EditExercisesGroup.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10) || 0;
      const isNew = id === 0;

      return { id, isNew };
    },
  },
  {
    path: "/exercisesGroups/edit/:id/linkedExercises",
    name: "EditExercisesGroupLinkedExercises",
    component: () => import(/* webpackChunkName: "editExercisesGroupLinkedExercises" */ "@/views/single/edit/EditExercisesGroupLinkedExercises.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/setsGroups",
    name: "SetsGroups",
    component: () => import(/* webpackChunkName: "setsGroups" */ "@/views/SetsGroups.vue"),
    meta: {
      isRequiresAuth: true,
      alternativeRoutesNames: ["ViewSetsGroup", "EditSetsGroup", "EditSetsGroupLinkedExercises"],
      displayName: "Sets's Groups",
      icon: "far fa-list-alt",
    },
  },
  {
    path: "/setsGroups/view/:id",
    name: "ViewSetsGroup",
    component: () => import(/* webpackChunkName: "viewSetsGroup" */ "@/views/single/view/ViewSetsGroup.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  {
    path: "/setsGroups/edit/:id?",
    name: "EditSetsGroup",
    component: () => import(/* webpackChunkName: "editSetsGroup" */ "@/views/single/edit/EditSetsGroup.vue"),
    meta: { isRequiresAuth: true },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10) || 0;
      const isNew = id === 0;

      return { id, isNew };
    },
  },
  {
    path: "/setsGroups/edit/:id/linkedExercises",
    name: "EditSetsGroupLinkedExercises",
    component: () => import(/* webpackChunkName: "editSetsGroupLinkedExercises" */ "@/views/single/edit/EditSetsGroupLinkedExercises.vue"),
    meta: {
      isRequiresAuth: true,
    },
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      return { id };
    },
  },
  //{
  //  path: "/stats",
  //  name: "Stats",
  //  component: () => import(/* webpackChunkName: "stats" */ "@/views/Stats.vue"),
  //  meta: {
  //    isRequiresAuth: true,
  //    displayName: "Stats",
  //    icon: "fas fa-chart-bar",
  //  },
  //},
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import(/* webpackChunkName: "feedback" */ "@/views/Feedback.vue"),
    meta: {
      isRequiresAuth: true,
      displayName: "Feedback",
      icon: "fas fa-hands",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue"),
    meta: {
      displayName: "Privacy",
      icon: "fas fa-user-secret",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      displayName: "About",
      icon: "fas fa-info",
    },
  },
  {
    path: "/testCards",
    name: "TestCards",
    component: () => import(/* webpackChunkName: "TestCards" */ "@/views/TestCards.vue"),
  },
  { path: "/:pathMatch(.*)*", component: Page404 },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
