import GlobalModal from "@/plugins/GlobalModal/components/GlobalModal.vue";

import { ref } from "vue";

export default {
  install(app, params = {}) {
    const instanceRef = ref(null);

    app.mixin({
      created() {
        if (this.$options.name === "GlobalModal") {
          instanceRef.value = this;
        }
      },
      beforeUnmount() {
        if (this.$options.name === "GlobalModal") {
          instanceRef.value = null;
        }
      },
    });

    app.component("global-modal", GlobalModal);

    app.config.globalProperties.$globalModal = instanceRef;
    app.provide("globalModal", instanceRef);
  },
};
